.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}




.NavigationFlexBox{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 5%;
  align-items: center;
}
.NavigationFlexBox a{
  display: flex;
  background-color: rgb(136, 193, 231);
  font-size:x-large;
  color:white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  flex:1;
  align-items: center; 
  justify-content: center;
}


.NavigationFlexBox a:hover{
  background-color: rgb(132, 187, 223);
}

.App .HomePage, .LoginRegisterPage, .NutritionPage, .ProfilePage, .FitnessPage{
  height: 95%;
}

p{
  font-size: calc(.4vw + .4vh + .4vmin);
}

button, input[type="button"], input[type="submit"], input[type="reset"], input,a {
  font-size: calc(.4vw + .4vh + .4vmin); /* Apply the viewport units directly to buttons */
  border-width: .1em;
}
a {
  font-size: calc(.5vw + .5vh + .5vmin) !important;
}

