.workout-log {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
}

.WorkoutLogItem {
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: .1em;
  border-color: #0d0808;
  border-radius: .5em;
  margin-top: 20px;
  padding: 20px;
  width: 90%;
  min-width: 300px; /* Added min-width to ensure it doesn't shrink too much */
}

.ExerciseLogEntry {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.ExerciseLogEntry p {
  margin-left: 1em;
}

.SetLogEntry {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-left: 1em;
}

/* Styles for the main workout log table */
.main-workout-log-table {
  width: 90%;
  margin-top: 20px;
  min-width: 300px;
  border-collapse: collapse; /* Collapse borders */
  border-spacing: 0; /* Remove space between cells */
}
.workoutTableRow:hover{
cursor: pointer;
}

.main-workout-log-table table {
  width: 100%;
}

.main-workout-log-table th,
.main-workout-log-table td {
  border: 1px solid #d1d1d1;
  padding: 8px;
  text-align: center;
}

.main-workout-log-table th {
  background-color: #00bfff;
}

/* Remove the commented-out style for .detailed-workout-log */

.detailed-workout-log h2 {
  margin-bottom: 10px;
}

.ExerciseLogEntry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SetLogEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

/* Styles for the table within .detailed-workout-log */
.detailed-workout-log table {
  width: 100%;
  border-collapse: collapse;
}

.detailed-workout-log th,
.detailed-workout-log td {
  border: 1px solid #060505;
  padding: 8px;
  text-align: center;
}

.detailed-workout-log th {
  background-color: #d1d1d1;
}

.selected-workout-details {
  margin-top: 10px;
  width: 350px;
  position: relative;
  background-color: #addbf3; /* Light gray background color */
  padding: 20px;
  border: 1px solid #060505;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.selected-workout-details button {
  position: absolute;
  top: 10px; /* Adjust top spacing */
  right: 10px; /* Adjust right spacing */
  margin-top: 0; /* No margin on top */
  background-color: #ff6347; /* Red button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 3px;
  padding: 8px 12px;
  cursor: pointer;
}

.selected-workout-details h3 {
  margin-bottom: 20px; /* Spacing below the heading */
}

.ExerciseName {
  text-decoration: underline;
}
