.HomePage {
    position: relative;
    background-image: url('/public/background.png');
    background-position: 50% 0%;
    background-repeat: no-repeat;
    width: 100%;
  }

.HomePage{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.HomePage .WeightGraph, .WorkoutLog{
  background-color: rgb(223, 223, 223);
  border-style: solid;
  border-width: .1em;
}

.HomePage .WeightGraph{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.HomePage .WeightGraph h4{
  border-bottom-style: solid;
  border-width: .1em;
  width: 100%;
  margin:0;
  margin-bottom: 1em;
  padding:0;
}




.HorizontalFlexBox{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 25%;
  width: 80%;
}
.HorizontalFlexBox .WeightGraph, .SpacerDiv, .RightContainer{
  width: 100%;
  height: 100%;
}
.recharts-responsive-container{
  height: 60%;
  overflow: hidden;
}





.WorkoutLog{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:40%;
  width: 80%;
}
.WorkoutLogContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
}
.WorkoutLogContainer .WorkoutLogRow{
  display: flex;
  width:100%;
  flex-direction: row;
  justify-content: space-between;
  height: 20%;
}
.WorkoutLogContainer .WorkoutLogRow p{
  padding-left: 1em;
  padding-right: 1em;
  margin: 0;
  align-self: center;
}
.WorkoutLog .PageChanger{
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.WorkoutLog .PageChanger button{
  height: 100%;
  padding: .5em;
  align-self: center;
  text-align: center;
  line-height: 100%;
}
.WorkoutLog .PageChanger p{
  margin:0;
}



.PageChanger button:hover{
  cursor: pointer;
  background-color: rgb(230, 230, 230);

}