.ExerciseComponent {
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Center the content */
    max-width: 400px; /* Limit the width */
}

.delete-exercise-button {
    margin-left: 10px; /* Add margin to separate the button */
    padding: 6px 12px;
    border: 2px solid #333;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .delete-exercise-button:hover {
    background-color: #ddd;
    color: #333;
  }
.input {
    width: 30px; /* Reduce width */
    height: 10px; /* Reduce height */
    padding: 6px; /* Adjust padding */
    border-radius: 10px; /* Make it round */
    background-color: #f0f0f0;
    color: #333;
    font-size: 12px; /* Reduce font size */
    font-family: Arial, sans-serif;
}

.input:focus {
    outline: none;
    border-color: #6c63ff;
    box-shadow: 0 0 5px rgba(108, 99, 255, 0.5);
}

.input::placeholder {
    color: #999;
}

.set-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the elements horizontally */
    margin-bottom: 10px;
    margin-top: 10px;
}

.set-container label {
    text-align: right; /* Align the set text to the right */
    margin-right: 10px; /* Add margin to the right of the label */
}

.set-container input {
    margin-left: 5px; /* Add margin to the left of the first input */
    margin-right: 10px; /* Adjust margin between inputs */
    width: 50px; /* Adjust width as needed */
}


.remove-set-button {
    display: flex;
    justify-content: space-between; /* Spread the buttons evenly */
    margin-top: 20px; /* Increase top margin for spacing */
    margin: 0 10px; /* Add margin to each button for spacing */
    font-size: 12px; /* Set the font size */
    padding: 6px 12px; /* Adjust padding for button size */
    border: 2px solid #333; /* Add border with a neutral color */
    border-radius: 4px; /* Apply rounded corners */
    background-color: #fff; /* White background */
    color: #333; /* Dark text color */
    cursor: pointer; /* Show pointer on hover */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition */
  }
  
  .remove-set-button:hover {
    background-color: #ddd; /* Light background on hover */
    color: #333; /* Dark text color on hover */
  }


.add-set-button {
    margin-top: 5px; /* Reduce margin */
    padding: 8px 12px; /* Adjust padding */
}



.PickExercise{
    position:absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 6%;
    left: 0;
    right: 0;
    bottom: 0;
  
}
.PickExercise .PickExerciseWindow{
    background-color: white;
    width: 70%;
    height: 60%;
    border-style: solid;
    border-radius: 1em;
    border-width: .1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.PickExercise .PickExerciseWindow .PickExerciseWindowList{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    width: 80%;
}
.PickExercise .PickExerciseWindow .PickExerciseWindowList .PickExerciseWindowListElement{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 33%;
    display: inline-block;
    border-style: solid;
    border-width: .1em;
    margin:1em;
}

.PickExercise .PickExerciseWindow .PickExerciseWindowList :hover{
    cursor: pointer;
}
