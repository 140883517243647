.RightContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.RightContainer .Tips, .RightContainer .LogWeight{
    height: 100%;
    width: 100%;
    border-style: solid;
    border-width: .1em;
    background-color: rgb(223, 223, 223);
}






.RightContainer .Tips h4{
    border-bottom-style: solid;
    border-width: .1em;
    width: 100%;
}
.RightContainer .Tips *{
    margin: 0;
    padding: 0;
}
.RightContainer .Tips:hover{
    cursor:pointer;
    background-color: rgb(230, 230, 230);

}




.RightContainer .LogWeight{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.RightContainer .LogWeight h4{
    margin:0;
    border-bottom-style: solid;
    border-width: .1em;
    width: 100%;
}
.RightContainer .LogWeight .LogWeightForm{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;
    height: 100%;
}
.RightContainer .LogWeight .LogWeightForm label{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.RightContainer .LogWeight .LogWeightForm label input{
    width: 40%;
    padding:0;
}

.RightContainer .LogWeight .LogWeightForm  button:hover{
    cursor: pointer;
    background-color: rgb(230, 230, 230);
}