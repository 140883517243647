.FitnessPage{
  display: flex;
  flex-direction: column;
  justify-content: space-between
}
.FitnessPage p{
  margin:0;
}
.FitnessPageMainRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.WorkoutFunctionContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.WorkoutLogContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}




.SwitchModeButton{
  width: fit-content;
  align-self: center;
}