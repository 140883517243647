.PPLDayComponent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    border-style: solid;
}
.ExerciseDescription{
    height: 25%;
    width: 100%;
}
.ExerciseDescription  p{
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1em;
    text-align: left;
}

.ExercisePictureRow{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 50%;
}


figure {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    justify-content: end;
}
