
.MainDiv{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  height: 95%;
  overflow-y: hidden;
}


*{
  border-width: .1em;
  border-color: rgba(0, 0, 0, 0.123);
  margin: 0;
  padding: 0;
}

.Day{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.Day .ExerciseRow{
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-width: .1em;
    justify-content: space-around;
    width: 80%;
    height: 15%;
}
.Day .ExerciseRow .ExerciseDescription{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.Day .ExerciseRow figure img{
    height: fit-content;
}




/*Styling for the beginner home*/
.BeginnerHome {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    height: 100%;
    width: 100%;
  }




.LeftColumn{
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  justify-content: space-between;
  align-content: space-around;
}
.StartingJourney{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 35%;
  border-style: solid;

}

.PPLDescription{
  display: flex;
  flex-direction: column;
  border-style: solid;
  width: 100%;
  height: 60%;
  justify-content:space-between;
}
.PPLTopDescription{
  height: 50%;
}

.HomeBoxText p{
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
  margin-bottom: 1em;
}

.HomeBoxText{
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
}


.SplitList{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.SplitRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:1em;
  height: 100%;
}
.SplitContainer{
  border-top-style: solid;

}
.SplitDescription{
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.SplitDescription p{
  text-align: center;
}













.Divider{
  border-style: solid;
  width: 0px;
  height: 100%;
}

h2{
  background-color: rgba(0, 0, 0, 0.123);
}





.RightColumn{
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  justify-content: space-between;
}
.DayList{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}


.SwitchModeButton{
  width: fit-content;
  align-self: center;
}
