.EditPassword{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: fit-content;
    align-items: center;
    justify-content: start;
    height: fit-content;
    background-color: rgba(245, 245, 245);
    padding: 1em;
    margin-top:1em;
}

.EditPassword p{
    font-size: large;
    margin:0;
}
.EditPassword .EditPasswordRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content;
    margin:1em
}