.LoginRegisterPage{
    display: flex;
    flex-direction: column;
    height:fit-content;
    width: 100%;
}
.LoginRegisterPage label{
    margin:1em
}

button:hover{
    cursor: pointer;
}