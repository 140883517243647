.ProfileRoot{
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: row;
}

.Selector{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20%;
    align-items: center;
    background-color: rgba(245, 245, 245, 0.719);
}
.Selector p{
    width: 100%;
    height: 10%;
    font-size: x-large;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0;
}
.Selector p:hover{
    cursor: pointer;
    background-color: rgb(230, 230, 230);

}
.ContentWindow{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    
}

.ButtonContainerForProfileView{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.ButtonContainerForProfileView button{
    margin-left: 1em;
}