
.myworkout-table {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
  width: 60%; /* Let the width adjust to the content */
  height: 100%;
  margin: 1em auto; /* Adjust margin as needed */
  padding: 1em; /* Decreased padding */
  border-radius: 8px;
  overflow: hidden;
  background-color: #addbf3; /* Add background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.workout-components {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the exercises */
}



.workout-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center horizontally */
  margin-bottom: 10px;
  background-color: #fff; /* Add background color */
  padding: 10px; /* Add padding */
  border-radius: 8px; /* Add border radius */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
  width: 320px; /* Set a fixed width for the exercise box */
  height: 150px; /* Set a fixed height for the exercise box */
  overflow: hidden; /* Hide overflowing content */
}

.exercise-with-button {
  display: flex;
  align-items: center;
}

.delete-exercise-button {
  margin-left: 5px;
}



.workout-name-input {
  border-radius: 10px; /* Make it round */
  margin: 10px auto;
  padding: 8px;
  width: 250px;
  display: block;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Spread the buttons evenly */
  margin-top: 20px; /* Increase top margin for spacing */
}

.button-container button {
  margin: 0 10px; /* Add margin to each button for spacing */
  font-size: 12px; /* Set the font size */
  padding: 6px 12px; /* Adjust padding for button size */
  border: 2px solid #333; /* Add border with a neutral color */
  border-radius: 4px; /* Apply rounded corners */
  background-color: #fff; /* White background */
  color: #333; /* Dark text color */
  cursor: pointer; /* Show pointer on hover */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition */
}

.button-container button:hover {
  background-color: #ddd; /* Light background on hover */
  color: #333; /* Dark text color on hover */
}

.create-workout-box,
.select-workout-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em auto;
  width: 100%;
  height: 100px;
  background-color: #00bfff;
  color: #fff;
  border-radius: .25em;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}
.create-workout-box h3,
.select-workout-box h3 {
  padding: 1em;
}
.create-workout-box:hover,
.select-workout-box:hover {
  background-color: #00a5ff;
}