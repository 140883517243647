.CreateWorkout{
    position:absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 6%;
    left: 0;
    right: 0;
    bottom: 0;
  
}
.CreateWorkout .CreateWorkoutWindow{
    background-color: white;
    width: 70%;
    height: 60%;
    border-style: solid;
    border-radius: 1em;
    border-width: .1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

/*Hides the scrollbar*/
.CreateWorkout .CreateWorkoutWindow::-webkit-scrollbar {
    display: none; /* This will hide the scrollbar */
}

/* Hide scrollbar for Firefox */
.CreateWorkout .CreateWorkoutWindow {
    scrollbar-width: none; /* This will hide the scrollbar in Firefox */
}


.CreateWorkout .CreateWorkoutWindow .PickExerciseWindowList{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    width: 100%;
}
.CreateWorkout .CreateWorkoutWindow .PickExerciseWindowList .PickExerciseWindowListElement{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 40%;
    min-height: 100%;
    display: inline-block;
    border-style: solid;
    border-width: .1em;
    margin:1em;
}
.CreatedWorkoutContentElement{
    display: flex;
}

.CreateWorkout .CreateWorkoutWindow .PickExerciseWindowList :hover{
    cursor: pointer;
}

.CreateWorkout .PickWorkoutWindowColumn .PickWorkoutWindowContent{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.CreateWorkout .CreateWorkoutWindow .CreateWorkoutRow{
    display: flex;
    flex-direction: row;
    width: 100%;

}

.CreatedWorkoutContentElement {
    display: flex;
    align-items: center;
    padding: 0.5em;
    margin: 0.5em;
    background-color: #e2e2e2; /* Background color */
    border: 1px solid #ccc; /* Border */
    border-radius: 5px; /* Rounded corners */
}

.MadeExercise input {
    padding: 0.5em; /* Add padding to the input */
    font-size: 1em; /* Set the font size */
    margin-top: 10px;
    margin-bottom: 10px;
}

.MadeExercise{
    padding-left: 1em;
    padding-right: 1em;
    border-right: solid;
    border-width: .1em;
    background-color: #f2f2f2;
}
.MadeExercise label{
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    justify-content: space-around;
}
.MadeExercise label p{
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    width: 100%;
}
