.HttpPopup{
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    width: fit-content  ;
    justify-self: center;
    align-self: center;
    border-radius: 10em;
    margin-top: 5em;
}
.HttpPopup *{
    padding-left: 1em;
    padding-right: 1em;
    margin:0;
}
