.BMICalculator{
    margin-top:1em;
    padding: 1em;
    width: 50%;
    height:100%;

}

.BMIContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(245, 245, 245, 0.719);
    padding: 1em;
}

.BMIRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}