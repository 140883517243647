.WeightGraph{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.WeightGraph h4{
    margin: 1em;
}